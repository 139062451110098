import React, { useState } from "react";
import "../../assets/scss/paper-kit/sections/_accordian.scss";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const contentClassName = `content ${isActive ? "active" : ""}`;

    return (
      <div key={index} className="accordion-item">
        <div
          className={`titleasd ${isActive ? "active" : ""}`}
          onClick={() => onTitleClick(index)}
        >
          {item.title}
        </div>
        <div className={contentClassName}>{item.content}</div>
      </div>
    );
  });

  return <div className="accordion">{renderedItems}</div>;
};

export default Accordion;
