import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function SectionExamples() {
  return (
    <>
      <div className="section section-examples section-dark" id="examples">
        <Container>
          <Row>
            <div className="title text-center">
              <h2 className="title">Steady to signup or subscription pages</h2>
              <h5 className="description">
                At Devtronics, We take immense pride in our diverse portfolio of
                cutting-edge projects. From innovative mobile applications to
                robust web solutions, our showcase of projects represents the
                pinnacle of our expertise and commitment to excellence. our
                projects stand as testaments to our unwavering pursuit of
                innovation. Explore our project gallery, and witness the
                tangible results of our dedication to creating software
                solutions that drive success and make a lasting impact.
              </h5>
            </div>
          </Row>
          <Row>
            <Col md="4">
              <div className="details-text">Walletly</div>
              <Card className="card-image card-hover-effect">
                <Link to="https://walletly.ai/" target="_blank">
                  <img alt="Project" src={require("assets/img/walletly.png")} />
                </Link>
              </Card>
              {/* <div className="details-text">Monster Hub</div>
              <Card className="card-image">
                <Link to="https://monsterhub.io/" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/monsterhub.png")}
                  />
                </Link>
              </Card> */}
              <div className="details-text">Cardly</div>
              <Card className="card-image">
                <Link to="/cardlyCasestudy" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/cardlysa.4e65181e.png")}
                  />
                </Link>
              </Card>
            </Col>
            <Col md="4">
              <div className="details-text">Coding School</div>
              <Card className="card-image">
                <Link to="https://the-cs.org/" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/codingschool.png")}
                  />
                </Link>
              </Card>
              <div className="details-text">Anastomosis</div>
              <Card className="card-image">
                <Link to="http://anastomosisdesignlab.com/" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/anastomosis.51498110.png")}
                  />
                </Link>
              </Card>
              <div className="details-text">Recrula</div>
              <Card className="card-image">
                <Link to="/recrulaCasestudy" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/recrula.png")}
                  />
                </Link>
              </Card>
              {/* <div className="details-text">AirTap</div>
              <Card className="card-image">
                <Link to="https://airtap.ca/" target="_blank">
                  <img alt="Project" src={require("assets/img/airtap.png")} />
                </Link>
              </Card> */}
              {/* <div className="details-text">Beasty</div>
              <Card className="card-image">
                <Link to="https://beasty.app/" target="_blank">
                  <img alt="Project" src={require("assets/img/BST.png")} />
                </Link>
              </Card> */}
            </Col>
            <Col md="4">
              <div className="details-text">Snap That Home</div>
              <Card className="card-image">
                <Link to="https://snapthathome.com.au/" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/snapthathome.png")}
                  />
                </Link>
              </Card>
              <div className="details-text">MicahGuru</div>
              <Card className="card-image">
                <Link to="https://micahguru.co.uk/" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/Micahguru.png")}
                  />
                </Link>
              </Card>
              <div className="details-text">Loyal Ideas</div>
              <Card className="card-image">
                <Link to="/loyalideasCasestudy" target="_blank">
                  <img
                    alt="Project"
                    src={require("assets/img/Frame 1 (1).png")}
                  />
                </Link>
              </Card>
              <Card className="card-image">
                <Link to="/moreprojects" target="_blank">
                  <img
                    alt="moreprojects icon"
                    src={require("assets/img/ryShfw.jpeg")}
                  />
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
