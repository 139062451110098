import React from 'react'

// reactstrap components
import FooterBlack from './FooterBlack'

function FooterAboutUs() {
  return (
    <>
      <FooterBlack />
    </>
  )
}

export default FooterAboutUs
