import React from 'react'
import blohheadimage from '../../assets/img/Project_69-07.jpg'

// reactstrap components
import { Button } from 'reactstrap'

// core components

function BlogPostHeader2() {
  let pageHeader = React.createRef()

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3
        pageHeader.current.style.transform =
          'translate3d(0,' + windowScrollTop + 'px,0)'
      }
      window.addEventListener('scroll', updateScroll)
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll)
      }
    }
  })

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url(${blohheadimage})`,
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <div className="motto">
            <h1
              className="title-uppercase text-center"
              style={{ fontWeight: 'bold' }}
            >
              MERN STACK FUTURE IN ML
            </h1>
            <h3 className="text-center " style={{ fontWeight: '500' }}>
              A Powerful Combination for the Future.
            </h3>
            <br />
            <Button
              className="btn-round"
              color="danger"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              size="lg"
            >
              <i className="fa fa-share-alt mr-1 " />
              Share Article
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogPostHeader2
