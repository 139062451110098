import React, { useState } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
// reactstrap components
import { Button, Input, Container, Row, Col } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// core components
import InfoNavbar from 'components/Navbars/InfoNavbar.js'
import FooterWhite from 'components/Footers/FooterWhite.js'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Loader from '../../components/Loader/loader'
import { Link, useHref } from 'react-router-dom'

const MapWrapper = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.445248, lng: 26.099672 }}
      defaultOptions={{
        styles: [
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e9e9e9',
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 29,
              },
              {
                weight: 0.2,
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 18,
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dedede',
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                visibility: 'on',
              },
              {
                color: '#ffffff',
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                saturation: 36,
              },
              {
                color: '#333333',
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f2f2f2',
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#fefefe',
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#fefefe',
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
        ],
        scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
      }}
    >
      <Marker position={{ lat: 44.445248, lng: 26.099672 }} />
    </GoogleMap>
  ))
)

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  message: '',
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
})

function ContactUs() {
  const [isLoading, setIsLoading] = useState(false)
  document.documentElement.classList.remove('nav-open')
  React.useEffect(() => {
    document.body.classList.add('contact-page')
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('contact-page')
    }
  })
  const handleSubmit = (values, { setSubmitting }) => {
    // You can handle form submission here and make your API call if needed.
    console.log('Form submitted with values:', values)
    setSubmitting(false)
    setIsLoading(true)
    // Make the API call
    //
    const handleSuccess = () => {
      // Display a success toast notification
      toast.success('Successfully sent!', {
        position: 'top-center', // You can change the position
        autoClose: 3000, // Duration (in milliseconds) for which the toast is shown
        hideProgressBar: false, // Show or hide the progress bar
        closeOnClick: true, // Close the toast when clicked
        pauseOnHover: true, // Pause the timer when hovered
      })
    }
    fetch(
      'https://api-integration-production.up.railway.app/api/contactus/emailsent',
      {
        method: 'POST', // or 'GET' depending on your API endpoint
        headers: {
          'Content-Type': 'application/json',
          // You can add any other headers if needed
        },
        body: JSON.stringify(values), // You may need to adjust this based on your API requirements
      }
    )
      .then((response) => response.json()) // Assuming the response is in JSON format
      .then((data) => {
        // Handle the API response here
        console.log('API Response:', data)
        // You can update your UI or state based on the response
      })
      .catch((error) => {
        // Handle errors here
        console.error('API Error:', error)
      })
      .finally(() => {
        // Set submitting to false after the API call is complete
        setSubmitting(false)
        setIsLoading(false)
        handleSuccess()
      })
  }

  // const handleSubmit = () => {
  //   console.log("works");
  // };
  return (
    <>
      <InfoNavbar />
      <div className="main">
        <div className="section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">
                  Get in Touch with <a href="/">Devtronics</a>
                </h2>
                <p>
                  At Devtronics, we're committed to fostering innovation,
                  empowering businesses, and delivering cutting-edge software
                  solutions that drive success. If you're ready to explore new
                  possibilities and elevate your technology stack, we'd love to
                  hear from you. Get in touch with us today, and let's embark on
                  a journey towards digital transformation together.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h3 className="title">
                  <small>Find us on social networks</small>
                </h3>
                <Button
                  href="https://twitter.com/Devtronics1"
                  target="_blank"
                  className="btn-just-icon mr-1"
                  color="twitter"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button
                  href="https://www.facebook.com/devtronics12/"
                  className="btn-just-icon mr-1"
                  target="_blank"
                  color="facebook"
                >
                  <i className="fa fa-facebook" />
                </Button>

                <Button
                  href="https://www.linkedin.com/company/81819987/admin/feed/posts/"
                  target="_blank"
                  className="btn-just-icon mr-1"
                  color="linkedin"
                >
                  <i class="fa fa-linkedin"></i>
                </Button>
                <Button
                  href="https://www.youtube.com/@devtronics4013"
                  target="_blank"
                  className="btn-just-icon"
                  color="youtube"
                >
                  <i className="fa fa-youtube" />
                </Button>
                <Button
                  href="https://www.upwork.com/o/companies/~01da996eb54b38da6e/"
                  target="_blank"
                  className="btn-just-icon ml-1"
                  color="success"
                >
                  <svg
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Upwork</title>
                    <path d="M18.561 13.158c-1.102 0-2.135-.467-3.074-1.227l.228-1.076.008-.042c.207-1.143.849-3.06 2.839-3.06 1.492 0 2.703 1.212 2.703 2.703-.001 1.489-1.212 2.702-2.704 2.702zm0-8.14c-2.539 0-4.51 1.649-5.31 4.366-1.22-1.834-2.148-4.036-2.687-5.892H7.828v7.112c-.002 1.406-1.141 2.546-2.547 2.548-1.405-.002-2.543-1.143-2.545-2.548V3.492H0v7.112c0 2.914 2.37 5.303 5.281 5.303 2.913 0 5.283-2.389 5.283-5.303v-1.19c.529 1.107 1.182 2.229 1.974 3.221l-1.673 7.873h2.797l1.213-5.71c1.063.679 2.285 1.109 3.686 1.109 3 0 5.439-2.452 5.439-5.45 0-3-2.439-5.439-5.439-5.439z" />
                  </svg>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h3 className="title">
                  <small>Or drop us a note</small>
                </h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form className="contact">
                    <Row>
                      <Col md="6">
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          as={Input}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          as={Input}
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Field
                          as={Input}
                          type="text"
                          name="email"
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          as={Input}
                          type="text"
                          name="subject"
                          placeholder="Subject"
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <Field
                      as={Input}
                      type="textarea"
                      name="message"
                      placeholder="Message"
                      rows="7"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-danger"
                    />
                    <Row>
                      <Col className="ml-auto mr-auto" md="6">
                        <Button
                          block
                          className="btn-round "
                          color="danger"
                          type="submit"
                          disabled={isLoading}
                          // onClick={handleSuccess}
                        >
                          {isLoading ? <Loader /> : 'Send'}
                        </Button>
                        <ToastContainer />
                      </Col>
                    </Row>
                  </Form>
                </Formik>
                <h4 className="visit font-weight-light">
                  <small>Or come and visit</small>
                </h4>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="big-map" id="contactUsMap">
        {/* <MapWrapper
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d462118.02491053584!2d67.1554619!3d25.1932024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c136f348f75a2f1%3A0xf1da2eb9a73db03a!2sDevtronics!5e0!3m2!1sen!2s!4v1695055925975!5m2!1sen!2s"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        /> */}
        <div class="w-full md:w-1/2 lg:w-1/3 mx-auto">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d462118.02491053584!2d67.1554619!3d25.1932024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c136f348f75a2f1%3A0xf1da2eb9a73db03a!2sDevtronics!5e0!3m2!1sen!2s!4v1695055925975!5m2!1sen!2s"
            width="100%"
            height="450"
            style={{ border: '0' }}
            allowfullscreen="0"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <FooterWhite />
      </div>
    </>
  )
}

export default ContactUs
