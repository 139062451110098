import React, { useState } from 'react';

function FAQ({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(false);

  const handleClick = () => setShowAnswer(!showAnswer);

  return (
    <div style={styles.faq}>
      <div style={styles.question} onClick={handleClick}>
        <p style={styles.questionP}>{question}</p>
        <span style={showAnswer ? styles.faqiconrotate : styles.faqicon}>+</span>
      </div>
      {showAnswer && <div style={styles['faq-answer']}>{answer}</div>}
    </div>
  );
}

const styles = {
  /* FAQ Container Styles */
  container: {
    width: '80%',
    margin: '0 auto',
    padding: '2rem',
  },

  /* FAQ Styles */
  faq: {
    marginBottom: '1rem',
    borderBottom: '1px solid #ddd',
    paddingBottom: '1rem',
  },

  /* FAQ Question Styles */
  question: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // background: 'gray',
    // padding: '4px',
  },

  questionP: {
    fontWeight: 'bold',
  },

  /* FAQ Icon Styles */
  faqicon: {
    width: '20px',
    height: '20px',
    textAlign: 'center',
    lineHeight: '20px',
    transition: 'transform 0.3s ease-in-out',
  },

  faqiconrotate: {
    transform: 'rotate(45deg)',
  },

  /* FAQ Answer Styles */
  'faq-answer': {
    paddingTop: '1rem',
    fontWeight: 500,
  },
};

export default FAQ;
