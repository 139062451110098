import React from "react";
import aboutusimg from "../../assets/img/campaign-creators-qCi_MzVODoU-unsplash.jpg";

// reactstrap components
import { Container } from "reactstrap";

// core components

function AboutUsHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${aboutusimg}')`,
        }}
      >
        {/* <div className="filter filter-danger" /> */}
        <div className="content-center text-center">
          <Container>
            <h1 style={{ fontWeight: "400" }}>
              Hello, <br />
              We are <a href="/">Devtronics</a>.
            </h1>
            <h3>Let us tell you more about what we do.</h3>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
