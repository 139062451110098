import React from 'react'
import blogimage from '../../assets/img/20943477.jpg'
import mernpic from '../../assets/img/MERN-Stack-Development.png'
import uximage from '../../assets/img/4491464.jpg'
import DangerNavbar from 'components/Navbars/DangerNavbar.js'
import FooterGray from 'components/Footers/FooterGray.js'
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap'

// core components

function BlogPosts() {
  document.documentElement.classList.remove('nav-open')
  React.useEffect(() => {
    document.body.classList.add('blog-posts')
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    return function cleanup() {
      document.body.classList.remove('blog-posts')
    }
  })
  return (
    <>
      <DangerNavbar />
      <div className='wrapper'>
        <div className='main'>
          <div className='section section-white'>
            <Container>
              <Row>
                <Col className='ml-auto mr-auto text-center title' md='6'>
                  <h2>
                    Enhancing Software Usability and Accessibility through User
                    Experience Design
                  </h2>
                  <h3 className='title-uppercase'>
                    <small>
                      Written by{' '}
                      <a href='https://www.linkedin.com/in/muhammad-faraz-668b26149'>
                        Muhammad Faraz
                      </a>
                    </small>
                  </h3>
                </Col>
              </Row>
              <div className='article'>
                <Row>
                  <Col className='ml-auto mr-auto' md='8'>
                    <Card className='card-blog card-plain text-center'>
                      <div className='card-image'>
                        <a href='#' onClick={(e) => e.preventDefault()}>
                          <img
                            alt='blogimage'
                            className='img img-raised'
                            src={uximage}
                          />
                        </a>
                      </div>
                      <CardBody style={{ width: '87%', margin: 'auto' }}>
                        <div className='card-category'>
                          <Badge
                            className='main-tag'
                            color='primary'
                            style={{ marginBottom: '20px' }}
                          >
                            Newer
                          </Badge>
                        </div>
                        <a href='javascrip: void(0);'>
                          <CardTitle tag='h3'>
                            Elevating Software Usability and Accessibility via
                            UX Design
                          </CardTitle>
                        </a>
                        <div className='card-description'>
                          <p>
                            In today's digital landscape, where software
                            solutions are abundant and competition is fierce,
                            user experience (UX) stands out as a critical factor
                            in determining the success of a product. Effective
                            UX design not only enhances the usability of
                            software but also ensures accessibility for users of
                            all backgrounds and abilities.
                          </p>
                        </div>
                      </CardBody>
                      <Button className='btn-round' color='danger' size='sm'>
                        <Link
                          to='/enhancing-software-usability-via-uxdesign'
                          className='mr-1'
                          style={{ color: 'white', fontWeight: 'bold' }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>

            <hr />
            <Container>
              <Row>
                <Col className='ml-auto mr-auto text-center title' md='6'>
                  <h2>
                    The MERN Stack and Big Data for Machine Learning: A Powerful
                    Combination for the Future
                  </h2>
                  <h3 className='title-uppercase'>
                    <small>
                      Written by{' '}
                      <a href='https://www.linkedin.com/in/ziaarsalan/'>
                        Zia Arsalan
                      </a>
                    </small>
                  </h3>
                </Col>
              </Row>
              <div className='article'>
                <Row>
                  <Col className='ml-auto mr-auto' md='8'>
                    <Card className='card-blog card-plain text-center'>
                      <div className='card-image'>
                        <a href='#' onClick={(e) => e.preventDefault()}>
                          <img
                            alt='blogimage'
                            className='img img-raised'
                            src={mernpic}
                          />
                        </a>
                      </div>
                      <CardBody style={{ width: '87%', margin: 'auto' }}>
                        <div className='card-category'>
                          <Badge
                            className='main-tag'
                            color='primary'
                            style={{ marginBottom: '20px' }}
                          >
                            Newer
                          </Badge>
                        </div>
                        <a href='javascrip: void(0);'>
                          <CardTitle tag='h3'>
                            The future of the MERN stack and big data for
                            machine learning
                          </CardTitle>
                        </a>
                        <div className='card-description'>
                          <p>
                            Machine learning (ML) is a rapidly growing field
                            with the potential to revolutionize many industries.
                            However, ML models can be complex and data-intensive
                            to train and deploy. This is where big data and the
                            MERN stack can come in handy.
                          </p>
                        </div>
                      </CardBody>
                      <Button className='btn-round' color='danger' size='sm'>
                        <Link
                          to='/mern-stack-future-in-machine-learning'
                          className='mr-1'
                          style={{ color: 'white', fontWeight: 'bold' }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </div>

              {/* <Row>
                <Col md="12">
                  <div className="pull-left">
                    <Button
                      className="btn-link btn-move-left"
                      color="default"
                      size="sm"
                    >
                      <i className="fa fa-angle-left mr-1" />
                      Older Posts
                    </Button>
                  </div>
                  <div className="pull-right">
                    <Button
                      className="btn-link btn-move-right font-weight-bolder"
                      color="default"
                      size="sm"
                    >
                      <Link to="/blog-post2" className="font-weight-bolder">
                        Newer Posts <i className="fa fa-angle-right" />
                      </Link>
                    </Button>
                  </div>
                </Col>
              </Row> */}
            </Container>

            {/* Second blog post  */}
            <hr />

            <Container>
              <Row>
                <Col className='ml-auto mr-auto text-center title' md='6'>
                  <h2>Examples of client success, powered by Devtronics</h2>
                  <h3 className='title-uppercase'>
                    <small>
                      Written by{' '}
                      <a href='https://www.linkedin.com/in/abdulmoiz1337/'>
                        Abdul Moiz
                      </a>
                    </small>
                  </h3>
                </Col>
              </Row>
              <div className='article'>
                <Row>
                  <Col className='ml-auto mr-auto' md='8'>
                    <Card className='card-blog card-plain text-center'>
                      <div className='card-image'>
                        <a href='#pablo' onClick={(e) => e.preventDefault()}>
                          <img
                            alt='blogimage'
                            className='img img-raised'
                            src={blogimage}
                          />
                        </a>
                      </div>
                      <CardBody style={{ width: '87%', margin: 'auto' }}>
                        <div className='card-category'>
                          <Badge
                            className='main-tag'
                            color='primary'
                            style={{ marginBottom: '20px' }}
                          >
                            Featured
                          </Badge>
                        </div>
                        <a href='javascrip: void(0);'>
                          <CardTitle tag='h3'>
                            Turning Challenges into Triumphs: A Client Success
                            Story
                          </CardTitle>
                        </a>
                        <div className='card-description'>
                          <p>
                            At
                            <a href='https://www.devtronics.co/'> Devtronics</a>
                            , we pride ourselves on our commitment to helping
                            our clients achieve their goals. Today, we're
                            excited to share the inspiring success story of one
                            of our valued clients, Their journey demonstrates
                            how collaboration, innovation, and dedication can
                            lead to remarkable results.
                          </p>
                        </div>
                      </CardBody>
                      <Button className='btn-round' color='danger' size='sm'>
                        <Link
                          to='/client-success-story'
                          className='mr-1'
                          style={{ color: 'white', fontWeight: 'bold' }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </div>

              {/* <Row>
                <Col md="12">
                  <div className="pull-left">
                    <Button
                      className="btn-link btn-move-left"
                      color="default"
                      size="sm"
                    >
                      <i className="fa fa-angle-left mr-1" />
                      Older Posts
                    </Button>
                  </div>
                  <div className="pull-right">
                    <Button
                      className="btn-link btn-move-right font-weight-bolder"
                      color="default"
                      size="sm"
                    >
                      <Link to="/blog-post2" className="font-weight-bolder">
                        Newer Posts <i className="fa fa-angle-right" />
                      </Link>
                    </Button>
                  </div>
                </Col>
              </Row> */}
            </Container>
          </div>
        </div>
      </div>
      <FooterGray />
    </>
  )
}

export default BlogPosts
