import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionComponents() {
  return (
    <>
      <div className="section section-components section-dark">
        <Row>
          <Col lg="6" md="12">
            <div className="image-container">
              <img
                alt="laptop"
                className="components-macbook"
                src={require("assets/img/presentation-page/laptop-basic.png")}
              />
              <img
                alt="table"
                className="table-img"
                src={require("assets/img/presentation-page/table.jpg")}
              />
              <img
                alt="sharebtn"
                className="share-btn-img"
                src={require("assets/img/presentation-page/share-btn.png")}
              />
              <img
                alt="colourcard"
                className="coloured-card-btn-img"
                src={require("assets/img/presentation-page/coloured-card-with-btn.png")}
              />
              <img
                alt="card"
                className="coloured-card-img"
                src={require("assets/img/presentation-page/coloured-card.png")}
              />
              <img
                alt="socialimg"
                className="social-img"
                src={require("assets/img/presentation-page/social-row.png")}
              />
              <img
                alt="pinbtnimg"
                className="pin-btn-img"
                src={require("assets/img/presentation-page/pin-btn.png")}
              />
            </div>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container">
              <h3 className="title">Enhancement</h3>
              <h6 className="category">
                WE BELIEVE IN ENHANCING YOUR WEBSITE WITH THE BASIC ELEMENTS
              </h6>
              <h5 className="description">
                Devtronics is a leading software development company, use
                technology to create innovative, intuitive and valuable
                solutions through the best of people to assist the clients in
                transforming their ideas into realities. Devtronics offers
                different types of software solutions based on your needs. We
                provide software solutions for Small Business, Banks and
                Enterprise level software developments. We are a leading tech
                services company, designing, developing and implementing IT
                solutions for its clients. Our wide range of services include
                software development, website design & development, mobile app
                development, etc.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;
