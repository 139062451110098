import React from 'react'

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap'

// core components

function SectionOverview() {
  return (
    <>
      <div className='section section-overview'>
        <Container fluid>
          <Row>
            <Col className='offset-md-2 text-center' md='8'>
              <div className='space-top' />
              <h2 className='title'>Want to know more?</h2>
            </Col>
            <Container>
              <div className='space-top' />
              <Row>
                <Col sm='3'>
                  <Card data-background='color' data-color='green'>
                    <CardBody className='text-center'>
                      <div className='card-icon'>
                        <i className='nc-icon nc-money-coins' />
                      </div>
                      <CardTitle tag='h4'>In your budget</CardTitle>
                      <p className='card-description'>
                        Save your hard earned and get an excellent web
                        application or software application made from the
                        scratch, we offer best prices
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm='3'>
                  <Card data-background='color' data-color='blue'>
                    <CardBody className='text-center'>
                      <div className='card-icon'>
                        <i className='nc-icon nc-watch-time' />
                      </div>
                      <CardTitle tag='h4'>Timely Submission</CardTitle>
                      <p className='card-description'>
                        We surf our excellency in reaching your provided
                        timings, if there's something important hitting up, then
                        we are all set up.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm='3'>
                  <Card data-background='color' data-color='purple'>
                    <CardBody className='text-center'>
                      <div className='card-icon'>
                        <i className='nc-icon nc-layout-11' />
                      </div>
                      <CardTitle tag='h4'>24/7 Connected</CardTitle>
                      <p className='card-description'>
                        If your website having any vulnerability or is being
                        attacked with any malware then we are there for you
                        whenever you need us
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm='3'>
                  <Card data-background='color' data-color='brown'>
                    <CardBody className='text-center'>
                      <div className='card-icon'>
                        <i className='nc-icon nc-align-center' />
                      </div>
                      <CardTitle tag='h4'>Trustworthy Customers</CardTitle>
                      <p className='card-description'>
                        We have a wide range of satisfied customers, some of
                        them are consecutively working,
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default SectionOverview
