import React from 'react'
import successimg from '../../assets/img/blogimage.jpg'
import blogsecimg from '../../assets/img/20945985.jpg'
import thirdimg from '../../assets/img/20943628.jpg'

// reactstrap components
import { Badge, Button, Card, Media, Container, Row, Col } from 'reactstrap'

// core components
import ColorNavbar from 'components/Navbars/ColorNavbar.js'
import BlogPostHeader from 'components/Headers/BlogPostHeader.js'
import FooterGray from 'components/Footers/FooterGray.js'

function BlogPost() {
  document.documentElement.classList.remove('nav-open')
  React.useEffect(() => {
    document.body.classList.add('blog-post')
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    return function cleanup() {
      document.body.classList.remove('blog-post')
    }
  })
  return (
    <>
      <ColorNavbar />
      <BlogPostHeader />
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                  <h2>Examples of Client Success</h2>
                  <h3 className="title-uppercase">
                    <small>
                      Written by{' '}
                      <a href="https://www.linkedin.com/in/abdulmoiz1337/">
                        Abdul Moiz
                      </a>
                    </small>
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <div className="text-center">
                    <Badge className="main-tag" color="warning">
                      Trending
                    </Badge>
                    <a href="javascrip: void(0);">
                      <h3 className="title">
                        Empowering Excellence: The Devtronics Client Success
                        Story
                      </h3>
                    </a>
                    <h6 className="title-uppercase">September 15, 2023</h6>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="8">
                  <a href="javascrip: void(0);">
                    <Card
                      data-radius="none"
                      style={{
                        backgroundImage: `url(${successimg})`,

                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    />
                  </a>
                  <div className="article-content">
                    <h4 style={{ fontWeight: '400', marginBottom: '10px' }}>
                      Preamble
                    </h4>
                    <p>
                      In the realm of technological innovation, achieving
                      success is often measured by the ability to overcome
                      challenges, adapt to evolving landscapes, and drive
                      tangible results. At Devtronics, we take pride in turning
                      these challenges into opportunities for our clients,
                      ushering them into a world of remarkable achievements.
                      Today, we're thrilled to share the inspiring journey of
                      one such client, highlighting how our partnership has led
                      to their remarkable transformation.
                    </p>

                    <blockquote className="blockquote">
                      <p>
                        "In the realm of IT, settling is not an option. If the
                        software isn't cutting-edge, innovate it. If the IT
                        roadmap isn't leading to success, chart a new course."
                      </p>
                      <footer>
                        <cite title="Source Title">-Steve Ballmer</cite>
                      </footer>
                    </blockquote>

                    <Row>
                      <Col md="6">
                        <a href="javascrip: void(0);">
                          <Card
                            data-radius="none"
                            style={{
                              backgroundImage: `url(${blogsecimg})`,

                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                          />
                          {/* end card */}
                        </a>
                      </Col>
                      <Col md="6">
                        <a href="javascrip: void(0);">
                          <Card
                            data-radius="none"
                            style={{
                              backgroundImage: `url(${thirdimg})`,

                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                          />
                          {/* end card */}
                        </a>
                      </Col>
                    </Row>
                    <p />
                    <h4 style={{ fontWeight: '400', marginBottom: '10px' }}>
                      Client Success: A Journey Begins
                    </h4>
                    <p>
                      <a href="/">Devtronics</a>, boasting a rich history and a
                      promising future, We don't just provide services; we forge
                      powerful partnerships. Our journey with clients commenced
                      time with an in-depth analysis of their unique needs,
                      goals, and pain points—much like a skilled coder
                      dissecting a complex script. Armed with this knowledge, we
                      crafted a tailor-made solution designed to address their
                      specific challenges.. It was evident that a strategic,
                      innovative approach was needed to guide them towards
                      success.
                    </p>
                    <h4 style={{ fontWeight: '400', marginBottom: '10px' }}>
                      Challenges Conquered:
                    </h4>
                    <p>
                      <strong>Digital Transformation:</strong> In an era marked
                      by rapid technological advancements, "Devtronics" needed
                      to modernize their operations. Our strategic guidance and
                      implementation of cutting-edge technologies streamlined
                      their processes, fostering adaptability in the face of
                      market changes.
                    </p>
                    <p>
                      <strong>Market Expansion:</strong> Amidst competitive
                      markets, expanding "Devtronics" reach was paramount. Our
                      data-driven marketing strategies, coupled with meticulous
                      SEO optimization, enabled them to reach new audiences,
                      bolstering their online presence significantly.
                    </p>
                    <p>
                      <strong>Operational Efficiency:</strong> alancing
                      cost-effectiveness with quality is no small feat. Through
                      process optimization and the integration of automation, we
                      helped "Devtronics" achieve substantial cost savings
                      without compromising the caliber of their products or
                      services.
                    </p>

                    <h4 style={{ fontWeight: '400', marginBottom: '10px' }}>
                      Results that Speak Volumes:
                    </h4>

                    <p>
                      The outcomes of our collaboration were nothing short of
                      astonishing. "Devtronics" witnessed an impressive growth
                      in revenue, a percentage decrease reduction in operational
                      costs, and a remarkable percentage increase surge in
                      customer engagement. Yet, beyond the numbers, the most
                      noteworthy achievement was specific achievement or impact,
                      which not only rejuvenated their business but also
                      reshaped its trajectory.
                    </p>

                    <h4 style={{ fontWeight: '400', marginBottom: '10px' }}>
                      Client Testimonial:
                    </h4>
                    <p>
                      <a href="/">Devtronics</a> has been an invaluable partner
                      on our journey. Their unwavering commitment, creative
                      solutions, and relentless dedication have not only
                      revitalized our business but also inspired us to reach new
                      heights. We eagerly anticipate continuing this
                      extraordinary journey together."
                    </p>
                    <h4>
                      <strong>Conclusions</strong>
                    </h4>
                    <p>
                      The tale of "Devtronics" transcends mere challenges; it
                      embodies the spirit of transformation. It serves as a
                      poignant reminder that success is attainable, even in the
                      face of adversity. If you aspire to revolutionize your
                      business and attain remarkable results, Devtronics is your
                      dedicated partner. Contact us today, and let's embark on
                      your journey to excellence. Unlock your potential and
                      chart a course towards greatness because, with Devtronics
                      by your side, success knows no bounds.
                    </p>
                  </div>
                  <br />
                  {/* <div className="article-footer">
                    <Container>
                      <Row>
                        <Col md="6">
                          <h5>Tags:</h5>
                          <label className="label label-default mr-1">
                            Motivational
                          </label>
                          <label className="label label-default mr-1">
                            Newsletter
                          </label>
                          <Badge color="warning">Trending</Badge>
                        </Col>
                        <Col className="ml-auto" md="4">
                          <div className="sharing">
                            <h5>Spread the word</h5>
                            <Button
                              className="btn-just-icon mr-1"
                              color="twitter"
                            >
                              <i className="fa fa-twitter" />
                            </Button>
                            <Button
                              className="btn-just-icon mr-1"
                              color="facebook"
                            >
                              <i className="fa fa-facebook" />
                            </Button>
                            <Button className="btn-just-icon" color="google">
                              <i className="fa fa-google" />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div> */}
                  <hr />
                </Col>
              </Row>
              {/* <Row>
                <div className="related-articles">
                  <h3 className="title">Related articles</h3>
                  <legend />
                  <Container>
                    <Row>
                      <Col md="4">
                        <a href="pkp">
                          <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/img/sections/damir-bosnjak.jpg")}
                          />
                        </a>
                        <p className="blog-title">
                          My Review of Pitchfork’s ‘Indie 500’ Album Review
                        </p>
                      </Col>
                      <Col md="4">
                        <a href="pkp">
                          <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/img/sections/por7o.jpg")}
                          />
                        </a>
                        <p className="blog-title">Top Events This Month</p>
                      </Col>
                      <Col md="4">
                        <a href="pkp">
                          <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/img/sections/jeff-sheldon.jpg")}
                          />
                        </a>
                        <p className="blog-title">
                          You Should Get Excited About Virtual Reality. Here’s
                          Why.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Row> */}
            </Container>
          </div>
        </div>
      </div>
      <FooterGray />
    </>
  )
}

export default BlogPost
