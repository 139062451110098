import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import muhammad from "../../assets/img/muhammad.jpg";
import saqib from "../../assets/img/saqib.jpg";
import wajeeh from "../../assets/img/wajeeh.jpg";
import izhan from "../../assets/img/izhan.jpg";
import amir from "../../assets/img/amir.jpg";
// core components

const items = [
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img alt="..." className=" img" src={saqib}></img>
        </div>
        <CardBody>
          <h5 className=" card-description" style={{ fontWeight: "500" }}>
            "Devtronics came to our rescue when we needed a complete website
            overhaul. Their work has been nothing short of exceptional. They
            ensured our website is not only attractive but also highly
            functional and user-friendly."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Engr.Saqib Akhter </CardTitle>
            <h6 className=" card-category">Business Managing Director</h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "0",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img alt="..." className=" img" src={muhammad}></img>
        </div>
        <CardBody>
          <h5 className=" card-description" style={{ fontWeight: "500" }}>
            "I stumbled upon Devtronics while searching for web development
            services, and I'm thrilled with the results. Their team crafted a
            sleek and responsive website for my business that exceeded my
            expectations. I highly recommend Devtronics for anyone looking to
            take their online presence to the next level."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Mohammed Alrshood </CardTitle>
            <h6 className=" card-category">Business Analysist</h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img alt="..." className=" img" src={amir}></img>
        </div>
        <CardBody>
          <h5 className=" card-description" style={{ fontWeight: "500" }}>
            "Devtronics took our outdated website and turned it into a modern
            masterpiece. Their team's creative flair and technical skills are
            top-notch. If you're looking for a web development partner, I highly
            recommend Devtronics."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Amir Hanif </CardTitle>
            <h6 className=" card-category">MicahGuru Formations</h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img alt="..." className=" img" src={izhan}></img>
        </div>
        <CardBody>
          <h5 className=" card-description" style={{ fontWeight: "500" }}>
            "I cannot express how delighted I am with the services provided by
            Devtronics. Their team created a beautiful and functional website
            for my e-commerce store. They were responsive to all my requests and
            ensured that the project was completed on schedule. I couldn't be
            happier with the results!"
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Izhan Sheikh </CardTitle>
            <h6 className=" card-category">Lead Product Designer</h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img alt="..." className=" img" src={wajeeh}></img>
        </div>
        <CardBody>
          <h5 className=" card-description" style={{ fontWeight: "500" }}>
            "Working with Devtronics has been an absolute game-changer for my
            startup. They not only designed a visually stunning website but also
            made sure it's user-friendly and optimized for SEO. I can't thank
            Devtronics enough for their outstanding work."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Wajeeh Haider </CardTitle>
            <h6 className=" card-category">
              Supply Chain and Logistics Expert
            </h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "2",
    altText: "",
    caption: "",
  },
];

function SectionTestimonials() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section section-testimonials">
        <Container fluid>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Feedback of our trustworthy customers</h2>
              <h5 className="description">
                Our services are being consumed by millions of people living
                worldwide, some of them have given this feedback
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="2">
              <div className="testimonials-people">
                <img
                  alt="..."
                  className="left-first-person add-animation"
                  src={
                    "https://s3.amazonaws.com/creativetim_bucket/photos/110862/thumb.?1482812727"
                  }
                />
                <img
                  alt="..."
                  className="left-second-person add-animation"
                  src={
                    "https://s3.amazonaws.com/creativetim_bucket/photos/139481/thumb.jpg?1485460613"
                  }
                />
                <img
                  alt="..."
                  className="left-third-person add-animation"
                  src={muhammad}
                />
                <img
                  alt="..."
                  className="left-fourth-person add-animation"
                  src={
                    "https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276"
                  }
                />
                <img
                  alt="..."
                  className="left-fifth-person add-animation"
                  src={
                    "https://s3.amazonaws.com/creativetim_bucket/photos/161506/thumb.?1489848178"
                  }
                />
                <img
                  alt="..."
                  className="left-sixth-person add-animation"
                  src={saqib}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item, key) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={key}
                      >
                        {item.content}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </div>
            </Col>
            <Col className="mr-auto" md="2">
              <div className="testimonials-people">
                <img
                  alt="..."
                  className="right-first-person add-animation"
                  src={
                    "https://s3.amazonaws.com/creativetim_bucket/photos/139481/thumb.jpg"
                  }
                />
                <img
                  alt="..."
                  className="right-second-person add-animation"
                  src={
                    "https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215"
                  }
                />
                <img
                  alt="..."
                  className="right-third-person add-animation"
                  src={izhan}
                />
                <img
                  alt="..."
                  className="right-fourth-person add-animation"
                  src={
                    "https://s3.amazonaws.com/creativetim_bucket/photos/118235/thumb.?1477435947"
                  }
                />
                <img
                  alt="..."
                  className="right-fifth-person add-animation"
                  src={wajeeh}
                />
                <img
                  alt="..."
                  className="right-sixth-person add-animation"
                  src={amir}
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div className="our-clients">
          <Container>
            <Row className="justify-content-center">
              <Col md="2" xs="5">
                <img alt="..." src={require("assets/img/demo/vodafone.jpg")} />
              </Col>
              <Col md="2" xs="5">
                <img alt="..." src={require("assets/img/demo/microsoft.jpg")} />
              </Col>
              <Col md="2" xs="5">
                <img alt="..." src={require("assets/img/demo/harvard.jpg")} />
              </Col>
              <Col md="2" xs="5">
                <img alt="..." src={require("assets/img/demo/stanford.jpg")} />
              </Col>
            </Row>
          </Container>
        </div> */}
      </div>
    </>
  );
}

export default SectionTestimonials;
