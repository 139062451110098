import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import FooterAboutUs from "components/Footers/FooterAboutUs.js";
import Accordion from "./accordion";

function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  const items = [
    {
      title:
        "Can you make changes to a product which other developers have already started? ",
      content:
        "Yes, we can. Our software engineers are well versed in various technologies and will practically always be able to continue the product started by some other team.",
    },
    {
      title: "How does the project work process take place?",
      content:
        "Each project is unique and requires a customized approach, but most of the development stages are relevant to all of them. However, the very process of their implementation may differ somewhat.",
    },
    {
      title:
        "Do you provide product support services after the app development is complete?",
      content:
        "Yes, we are always glad to provide technical support and service the apps we have developed. We can also further develop your software product if you need to implement new features or integrate third-party services.",
    },
    {
      title: "What is the cost of your services?",
      content:
        "It really depends on what service levels you need and what you are looking to achieve. Although every business needs IT in today’s fast-paced world, we do have different tiered structures that offer different service levels, which define hours, fees and response times. We will work within the framework of your needs, budget and requirements.",
    },
  ];
  return (
    <>
      <ColorNavbar />
      <AboutUsHeader />
      <div className="main">
        <div className="section">
          <Container>
            <h3 className="title-uppercase">We build great products.</h3>
            <p>
              At <a href="/">Devtronics</a>, we don't just build products; we
              craft extraordinary solutions that elevate your business. Our
              unwavering commitment to innovation and excellence empowers you to
              not just survive but thrive in today's ever-evolving and dynamic
              market.
            </p>
            <p>
              With years of experience in the industry, our experts are
              well-equipped to collaboratively administrate empowered markets
              via plug-and-play networks, ensuring your success every step of
              the way. Your satisfaction is our priority. We dramatically
              visualize customer-directed convergence, ensuring that our
              solutions address your pain points and drive positive outcomes.
            </p>
            <h3 className="title-uppercase">
              Data-Driven Excellence: Pioneering IT Solutions for a Brighter
              Future.
            </h3>
            <p>
              We believe in the importance of one-to-one customer service. Our
              dedicated team is here to provide you with robust ideas and
              support every step of the way. Let's work together to transform
              your IT landscape and drive your business to new levels of
              success. Contact <a href="/">Devtronics</a> today.
            </p>
            <h2 className="text-center creators">Creators</h2>
            <Row className="d-flex justify-content-center">
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div className="card-avatar">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          src={require("../../assets/img/zia.jpg")}
                        />
                        <CardTitle tag="h4">Zia Arsalan</CardTitle>
                      </a>
                    </div>
                    <p
                      className="card-description text-center"
                      style={{ width: "300px", height: "185px" }}
                    >
                      "Under the guidance of CEO Zia Arsalan, Devtronics has
                      blossomed into a symphony of success, Zia approach fosters
                      an environment where each team member's unwavering
                      confidence in their unique skill set not only elevates
                      their own capabilities but also encourages them to applaud
                      the expertise of their colleagues."
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon mr-1"
                      color="linkedin"
                      href="https://www.linkedin.com/in/ziaarsalan/"
                      // onClick={(e) => e.preventDefault()}
                      target={"_blank"}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>

                    <Button
                      className="btn-just-icon"
                      color="facebook"
                      href="https://www.facebook.com/zia.arsalan.908"
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div className="card-avatar">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          src={require("../../assets/img/usama (1).jpeg")}
                        />
                        <CardTitle tag="h4">Muhammad Usama</CardTitle>
                      </a>
                    </div>
                    <p
                      className="card-description text-center"
                      style={{ width: "300px" }}
                    >
                      Muhammad Usama, our MERN Stack Developer conducting the
                      creation of cutting-edge web applications with precision
                      and finesse.Usama orchestrates the intricate components of
                      the MERN stack—MongoDB, Express.js, React, and Node.js—to
                      produce seamless.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon mr-1"
                      color="linkedin"
                      href="https://www.linkedin.com/in/usama-yousuf-6899b1203?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                      // onClick={(e) => e.preventDefault()}
                      target={"_blank"}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                    <Button
                      className="btn-just-icon"
                      color="instagram"
                      href="#"
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div className="card-avatar">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          src={require("../../assets/img/WhatsApp Image 2023-09-20 at 9.50.08 PM.jpeg")}
                        />
                        <CardTitle tag="h4">Abdul Moiz</CardTitle>
                      </a>
                    </div>
                    <p
                      className="card-description text-center"
                      style={{ width: "300px" }}
                    >
                      Abdul Moiz, Meticulously crafts elegant and efficient web
                      solutions, turning ideas into seamless, user-friendly
                      experiences. His dedication and expertise not only make
                      him an invaluable member of our team but also a key
                      contributor to our ongoing success.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon mr-1"
                      color="linkedin"
                      href="https://www.linkedin.com/in/abdulmoiz1337/"
                      target={"_blank"}
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                    <Button
                      className="btn-just-icon"
                      color="instagram"
                      href="https://www.facebook.com/mughal023/"
                      target={"_blank"}
                      // onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <h3 className="more-info">Need more information?</h3>
            <div>
              <Accordion items={items} />
            </div>
          </Container>
        </div>
      </div>
      <FooterAboutUs />
    </>
  );
}

export default AboutUs;
