import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";

// core components

function SectionCards() {
  return (
    <>
      <div className="section section-cards section-gold">
        <div className="codrops-header">
          <Row>
            <Col lg="4" md="6">
              <div className="section-description">
                <h3 className="title">User Friendly Themes</h3>
                <h6 className="category">MAKING YOUR DESIRES REACHABLE</h6>
                <h5 className="description">
                  This is one most important aspect in life of a user, that the
                  theme they using can be handled easily or not, we cares for
                  them and that is why provide relevant and easily handled
                  themes, having various functions as per their requirement.
                  With various functions it also includes easy mode of
                  connecting social platforms which also provides the user an
                  helping hand to boost their services or products.
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <section className="section-intro">
          <div className="isolayer isolayer--deco1 isolayer--shadow js">
            <ul className="grid grid--loaded">
              <li className="grid__item first-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Purplecard"
                    className="grid__img layer"
                    src={require("assets/img/presentation-page/try/purple-card.png")}
                  />
                </a>
              </li>
              <li className="grid__item second-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="twittercard"
                    className="grid__img layer"
                    src={require("assets/img/presentation-page/try/twitter-card.JPG")}
                  />
                </a>
              </li>
              <li className="grid__item third-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="facebookcard"
                    className="grid__img layer"
                    src={require("assets/img/presentation-page/try/facebook-card.JPG")}
                  />
                </a>
              </li>
              <li className="grid__item fourth-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="landingpage"
                    className="grid__img layer"
                    src={require("assets/img/demos.creative-tim.com_nuxt-argon-dashboard-pro_dashboards_landing.png")}
                  />
                </a>
              </li>
              <li className="grid__item fifth-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="demo"
                    className="grid__img layer"
                    src={require("assets/img/demos.creative-tim.com_vision-ui-dashboard-react___ga=2.220534552.1856791023.1695315713-1905796898.1690893576.png")}
                  />
                </a>
              </li>
              <li className="grid__item sixth-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="capture"
                    className="grid__img layer"
                    src={require("assets/img/presentation-page/try/capture.jpg")}
                  />
                </a>
              </li>
              <li className="grid__item seventh-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="screenshot"
                    className="grid__img layer"
                    src={require("assets/img/Screenshot (27).png")}
                  />
                </a>
              </li>
              <li className="grid__item eight-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="testimonial"
                    className="grid__img layer"
                    src={require("assets/img/presentation-page/try/testimonal-card.JPG")}
                  />
                </a>
              </li>
              <li className="grid__item ninth-card">
                <a
                  className="grid__link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="pricingcard"
                    className="grid__img layer"
                    src={require("assets/img/presentation-page/try/pricing-card-icon.JPG")}
                  />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

export default SectionCards;
