import React from 'react';
import logo from '../../assets/img/logo.jpg'
import clogo from '../../assets/img/image.png'
import DangerNavbar from 'components/Navbars/DangerNavbar'
import FooterGray from 'components/Footers/FooterGray';



const CardlyCS = () => {
  return (
    <>
    {/* <header style={style.header}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <img style={style.logosize} src={logo} alt='logo' />
            <div style={style.logo}>Cardly</div>
        </div>
      <div>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={style.link}>in</a>
      </div>
    </header> */}
      <DangerNavbar />

    <div style={style.container}>
        <img style={style.cover} src={logo} alt='cover' />
        <div style={style.profilePicHolder}>
        <img style={style.profilePic} src={clogo} />
        </div>
        <div style={style.body}>
          <h1>Welcome to Our Website</h1>
          <p>In response to a critical business requirement from the CEO of X-age Company,
             we were tasked with the development of a digital business card solution, 
             enabling their clients to access their information seamlessly. 
             The project was characterized by its urgency, with a strict deadline of 40 days imposed by the client
          </p>
          <h1>IMPLEMENTATION</h1>
          <p>Our approach to meeting this challenging timeline was executed with the utmost professionalism and efficiency.
             A team of four highly skilled professionals was assembled to spearhead the project, each possessing specialized 
             expertise essential to its successful execution.
          </p>
          <p>
            Throughout the project's duration, our team maintained a stringent focus on project management and quality assurance. 
            We meticulously planned every phase of development, ensuring that all milestones were achieved according to the predetermined 
            timeline. This involved continuous communication and collaboration within the team, regular progress assessments, and proactive 
            issue resolution.
          </p>
          <p>
            Our commitment to meeting the client's needs was unwavering, and we leveraged the collective experience of our team 
            members to navigate any obstacles encountered during the development process. Our dedication to excellence and efficient 
            project management allowed us to deliver the digital business card solution on time, precisely as per the client's expectations.
          </p>
          <p>
            In summary, our ability to swiftly assemble a proficient team, coupled with our rigorous project management practices, 
            ensured that we not only met but exceeded the client's stringent deadline. The successful delivery of this project 
            underscores our commitment to professionalism and excellence in every endeavor we undertake.
          </p>
          <h1>TECHNOLOGIES</h1>
          <ul>
              <li>Node.JS</li>
              <li>React.JS</li>
              <li>Next.Js</li>
              <li>SQL</li>
              <li>Python</li>
              <li>ODOO CRM</li>
          </ul>
          {/* <div style={style.faqcontainer}>
            <h2>FAQs</h2>
          <FAQ 
            question="Q:  What services do you provide?"
            answer="We specialise in various technologies and programming languages, such as Java, Python, NET, PHP, JavaScript, 
            React.JS React Native, Angulor, Node.js, and more. We stay up to date with the latest industry trends and continuously 
            expand our skill set"
          />
          <FAQ 
            question="Q:  What services do you provide?"
            answer="We specialise in various technologies and programming languages, such as Java, Python, NET, PHP, JavaScript, 
            React.JS React Native, Angulor, Node.js, and more. We stay up to date with the latest industry trends and continuously 
            expand our skill set"
          />
          <FAQ 
            question="Q:  What services do you provide?"
            answer="We specialise in various technologies and programming languages, such as Java, Python, NET, PHP, JavaScript, 
            React.JS React Native, Angulor, Node.js, and more. We stay up to date with the latest industry trends and continuously 
            expand our skill set"
          />
          <FAQ 
            question="Q:  What services do you provide?"
            answer="We specialise in various technologies and programming languages, such as Java, Python, NET, PHP, JavaScript, 
            React.JS React Native, Angulor, Node.js, and more. We stay up to date with the latest industry trends and continuously 
            expand our skill set"
          />
          </div> */}
        </div>
    </div>

    <FooterGray />


    {/* <footer style={style.footer}>
      &copy; 2024 Cardly
    </footer> */}
    </>
  );
};

const style = {
    faqcontainer: {
        width: '80%',
        margin: '0 auto',
        padding: '2rem',
      },
    container: {
        // height: 'calc(100vh - 40px)', // Set height to fill the viewport, adjust based on your footer height
        // maxHeight: 'calc(100vh - 40px)', // Set maximum height, adjust based on your footer height
        // overflowY: 'auto', // Add vertical scrollbar if content overflows
        position: 'relative', // Ensure position is relative to contain absolutely positioned elements
    },
    cover: {
        width: '100%',
        height: 'auto',
    },
    profilePicHolder: {
        position: 'absolute',
        top: 'calc(100% - 980px)', // Adjust position as needed, 50px from the bottom
        left: '10%', // Center horizontally
        // transform: 'translateX(-50%)', // Center horizontally
        width: '180px', // Adjust width as needed
        height: '180px', // Adjust height as needed
        borderRadius: '50%', // To make it circular
        backgroundColor: 'white', // Placeholder color
    },
    profilePic: {
        position: 'absolute',
        top: 'calc(100% - 140px)', // Adjust position as needed, 50px from the bottom
        left: '16%', // Center horizontally
        // transform: 'translateX(-50%)', // Center horizontally
        width: '130px', // Adjust width as needed
        height: '100px', // Adjust height as needed
    },
    body: {
        backgroundColor: 'lightblue', // Placeholder color
        color: 'black',
        padding: '80px 180px 80px 180px', // Adjust padding as needed
    },
    header: {
      backgroundColor: '#d9534f',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 20px',
      alignItems: 'center',
    },
    logosize: {
      height: '30px',
      width: '30px',
    },
    logo: {
      color: '#fff',
      margin: '0',
    },
    link: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: '1.5rem',
    },
    footer: {
      backgroundColor: '#d9534f',
      padding: '20px',
      textAlign: 'center',
      color: 'white',
      position: 'fixed', // Fix the footer at the bottom
      width: '100%',
      bottom: 0, // Ensure the footer stays at the bottom
      zIndex: 2, // Ensure footer is above other content
    },
};

export default CardlyCS;
