import React from 'react'
import images from '../../assets/img/softwareS.png'
import secimages from '../../assets/img/Digital.png'
import thirdimages from '../../assets/img/desiging.png'
import companylogo from '../../assets/img/devtronlog.png'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap'

// core components

function SectionSharing() {
  return (
    <>
      <div className='section section-sharing section-dark ' id='demoPay'>
        <Container>
          <Row>
            <div className='title text-center'>
              <h3 className='title'>
                Our exceptional IT services are available for you to take
                advantage of.
              </h3>
              <p className='description'>
                Our exceptional IT Services includes wide range solutions,
                Software Application, Web Application, Digital Marketing,
                Designing, so what are you waiting for? Let us answer your
                unanswered queries.
              </p>
              <br />
            </div>
            <div class='col-md-10 ml-auto mr-auto'>
              <div class='space-top'></div>
              <div class='row'>
                <div class='col-md-4'>
                  <div class='card card-pricing'>
                    <div class='card-body'>
                      <div class='card-icon'>
                        <span class='icon-simple'>
                          <img
                            src={images}
                            alt='software pic'
                            style={{ width: '100px' }}
                          />
                        </span>
                      </div>
                      <h3 class='card-title'>Software Solutions</h3>
                      <p
                        class='card-description'
                        style={{ height: '200px', color: '#000000' }}
                      >
                        We offer a wide range of software solutions that are
                        designed to help businesses and organizations of all
                        sizes to streamline their operations and improve their
                        performance. Our solutions are built using the latest
                        technologies and are tailored to meet the unique needs
                        of each client.
                      </p>
                      <div class='card-footer mb-3'>
                        <a href='./contact-us' class='btn btn-danger btn-round'>
                          Contact Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-md-4'>
                  <div class='card card-pricing'>
                    <div class='card-body'>
                      <div class='card-icon'>
                        <span class='icon-simple'>
                          <img
                            src={secimages}
                            alt='secimage'
                            style={{ width: '100px' }}
                          />
                        </span>
                      </div>
                      <h3 class='card-title'>Digital Marketing</h3>
                      <p
                        class='card-description'
                        style={{ height: '200px', color: '#000000' }}
                      >
                        In digital marketing we offer marketing through wide
                        range of social media platforms, facebook, instagram,
                        linkedin, twitter etc and also help in increasing your
                        online presence through seo as well
                      </p>
                      <div class='card-footer mb-3'>
                        <a href='./contact-us' class='btn btn-danger btn-round'>
                          Contact Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-md-4'>
                  <div class='card card-pricing'>
                    <div class='card-body'>
                      <div class='card-icon'>
                        <span class='icon-simple'>
                          <img
                            src={thirdimages}
                            alt='Designimage'
                            style={{ width: '100px' }}
                          />
                        </span>
                      </div>
                      <h3 class='card-title'>Design</h3>
                      <p
                        class='card-description'
                        style={{ height: '200px', color: '#000000' }}
                      >
                        In designing we offer customized designs of websites,
                        figma and also provide graphic designing through, eye
                        catchy designs, professional which helps in boosting
                        your sales.
                      </p>
                      <div class='card-footer mb-3'>
                        <a href='./contact-us' class='btn btn-danger btn-round'>
                          Contact Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Col className='offset-md-2 text-center' md='8' />
          </Row>
          {/* <div className="text-center">
            <span>Pay one time with:</span>
            <i className="fa fa-cc-paypal fa-pay mr-1" />
            <i className="fa fa-cc-visa fa-pay mr-1" />
            <i className="fa fa-cc-mastercard fa-pay mr-1" />
            <i className="fa fa-cc-amex fa-pay" />
            <br />
            <br />
          </div> */}
          <div
            className='title text-center mt-5 pt-2'
            style={{
              textAlign: 'center',
              margin: 'auto',
              maxWidth: '800px',
              padding: '0 10px',
            }}
          >
            <h3 style={{ fontSize: '16px', lineHeight: '1.5' }}>
              {/* <img
                src={companylogo}
                alt=''
                style={{
                  width: '30px',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              /> */}
              Devtronics is a leading software company specializing in software
              and web services. Our expert team is dedicated to providing
              innovative solutions tailored to meet the unique needs of
              businesses. With a focus on product design, development, and
              technology acceleration, we strive to deliver exceptional results
              that drive success. Partner with Devtronics and experience
              excellence in digital development.
            </h3>
          </div>
        </Container>
      </div>
    </>
  )
}

export default SectionSharing
