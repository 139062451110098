import React from 'react'
import { Link, NavLink } from 'react-router-dom'
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js'
import companylogo from '../../assets/img/devtronlog.png'

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from 'reactstrap'
// core components

function WhiteNavbar() {
  const [bodyClick, setBodyClick] = React.useState(false)
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const customLinkStyle = {
    color: 'inherit', // Inherit the parent's text color
    textDecoration: 'none', // Remove underline
    fontWeight: 'bold',
    // Add more styles as needed
  }
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById('navbar-main'))
    // initialise
    headroom.init()
  })
  return (
    <>
      {bodyClick ? (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle('nav-open')
            setBodyClick(false)
            setCollapseOpen(false)
          }}
        />
      ) : null}
      <Navbar className='fixed-top' expand='lg' id='navbar-main'>
        <Container>
          <div className='navbar-translate'>
            <img
              src={companylogo}
              alt=''
              style={{ width: '40px', marginLeft: '15px', height: 'auto' }}
            />

            <NavbarBrand id='navbar-brand' to='/index' tag={Link}>
              DEVTRONICS
            </NavbarBrand>

            <button
              className='navbar-toggler'
              id='navigation'
              type='button'
              onClick={() => {
                document.documentElement.classList.toggle('nav-open')
                setBodyClick(true)
                setCollapseOpen(true)
              }}
            >
              <span className='navbar-toggler-bar bar1' />
              <span className='navbar-toggler-bar bar2' />
              <span className='navbar-toggler-bar bar3' />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className='ml-auto' navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className='mr-2' color='default' nav>
                  <NavLink to='/blog-posts' style={customLinkStyle}>
                    Blogs
                  </NavLink>
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className='mr-2' color='default' nav>
                  <NavLink to='/about-us' style={customLinkStyle}>
                    About Us
                  </NavLink>
                </DropdownToggle>
              </UncontrolledDropdown>
              <NavItem>
                <Button
                  className='btn-round'
                  color='danger'
                  href='/contact-us'
                  target='_blank'
                >
                  <i className='nc-icon nc-cart-simple' /> GET A QUOTE
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default WhiteNavbar
