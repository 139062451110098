import React from 'react';
import loyalcover from '../../assets/img/Frame 1 (1).png';
import loyal from '../../assets/img/loyal.png';
import DangerNavbar from 'components/Navbars/DangerNavbar';
import FooterGray from 'components/Footers/FooterGray';

const Loyaldeas = () => {
  return (
    <>
      <DangerNavbar />

      <div style={styles.container}>
        <div >
          <img style={{...styles.cover, backgroundSize: 'cover'}} src={loyalcover} alt='cover' />
        </div>
        <div style={styles.profilePicHolder}>
          <img style={styles.profilePic} src={loyal} alt='profile' />
        </div>
        <div style={styles.body}>
          <h1 style={styles.heading}>Loyal Ideas - Digital Loyalty Program</h1>
          <h3 style={styles.subheading}>Challenge:</h3>
          <p>In the competitive landscape of customer retention, organizations are constantly seeking effective methods to enhance customer loyalty and engagement. Traditional loyalty programs often fall short in providing the flexibility and ease of use that modern consumers expect. Organizations needed a digital solution that not only simplified loyalty program management but also offered advanced features to engage customers more effectively.</p>
          
          <h3 style={styles.subheading}>Our Approach:</h3>
          <p>We developed Loyal Ideas, a digital loyalty program designed to meet the evolving needs of businesses and their customers. Loyal Ideas allows organizations to sign up and select a card design that aligns with their brand. The app generates a QR code that customers can scan to register and add the card to their Apple or Google Wallet. After every purchase, points are added to the pass, with the point value set by the organization. These points can be redeemed, providing tangible benefits to loyal customers.</p>
          
          <p>Loyal Ideas also equips organizations with valuable customer data and team management capabilities. One of the standout features is the ability for organizations to send push notifications to customers who have added the pass to their wallet, serving as an effective marketing strategy. This ensures continuous engagement and offers a direct channel to communicate promotions, updates, and exclusive offers.</p>
          
          <h3 style={styles.subheading}>TECHNOLOGIES</h3>
          <ul>
            <li>Node.JS</li>
            <li>React.JS</li>
            <li>Express.Js</li>
            <li>MongoDB</li>
          </ul>

          <h3 style={styles.subheading}>Conclusion</h3>
          <p>Loyal Ideas revolutionizes the traditional loyalty program by offering a digital, user-friendly solution that enhances customer engagement and retention. With features like customizable card designs, seamless integration with digital wallets, and targeted push notifications, Loyal Ideas empowers organizations to build stronger relationships with their customers and drive repeat business. As we continue to refine and enhance Loyal Ideas, we are committed to providing businesses with innovative tools to succeed in the competitive market.</p>
        </div>
      </div>

      <FooterGray />
    </>
  );
};

const styles = {
  container: {
    marginTop: '70px',
    position: 'relative',
  },
  cover: {
    // backgroundsize: 'cover',
    // background: 'cover',
    width: '100%',
    height: '550px',
  },
  profilePicHolder: {
    position: 'absolute',
    top: 'calc(100% - 960px)',
    left: '10%',
    width: '180px',
    height: '180px',
    borderRadius: '50%',
    backgroundColor: 'white',
  },
  profilePic: {
    position: 'absolute',
    top: 'calc(100% - 140px)',
    left: '23%',
    width: '100px',
    height: '100px',
  },
  body: {
    backgroundColor: 'lightblue',
    color: 'black',
    padding: '80px 180px', // Adjusted padding for responsiveness
  },
  heading: {
    marginTop: '40px',
    fontWeight: 500,
    fontSize: '40px',
  },
  subheading: {
    fontWeight: 500,
  },
};

export default Loyaldeas;
