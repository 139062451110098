import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContent() {
  return (
    <>
      <div id="fadeInAnim">
        <div className="section section-content section-gray">
          <Container>
            <Row>
              <Col md="7">
                <div className="image-container">
                  <img
                    alt="Animation"
                    className="img"
                    src={require("assets/img/presentation-page/ipad-content-2.png")}
                  />
                  <img
                    alt="Animation"
                    className="area-img add-animation"
                    src={require("assets/img/presentation-page/ipad-left-img.jpg")}
                  />
                  <img
                    alt="Animation"
                    className="info-img add-animation"
                    src={require("assets/img/presentation-page/ipad-right-img.jpg")}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="section-description">
                  <h3 className="title">Tempting designs for content</h3>
                  <h6 className="category">MAKING YOUR CONTENT APPEALING</h6>
                  <h5 className="description">
                    With an attractive seo friendly content it is highly
                    important to work on it's appearance so it sounds more
                    catchy to the viewer and for this we work on various
                    options, fonts, subtle patterns or textures, white space,
                    big images, social links, color and contrast, grids and
                    symmetry, graphics and mobile optimization etc. All this
                    will leave a professional impact on the viewer, which will
                    make him visit again.ebsite.
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
