import DangerNavbar from "components/Navbars/DangerNavbar";
import React, { useState } from "react";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
import "../../assets/scss/paper-kit/sections/moreprojects.scss";
import SectionSections from "views/presentation-sections/SectionSections";
import { Button, Container, Row, Col } from "reactstrap";
import FooterGray from "components/Footers/FooterGray";
import FooterBlack from "components/Footers/FooterBlack";

function Moreprojects({ className, ...rest }) {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <div>
        <DangerNavbar />
        <section className="counter">
          <div className="countertitle">
            <h1 className="counterhead">Our Achievements</h1>
          </div>

          <div className="counter-row">
            <div className="counter-column">
              <strong data-number="15">
                <CountUp
                  {...rest}
                  start={viewPortEntered ? null : 0}
                  end={15}
                  suffix="+"
                >
                  {({ countUpRef }) => {
                    return (
                      <ReactVisibilitySensor
                        active={!viewPortEntered}
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setViewPortEntered(true);
                          }
                        }}
                        delayedCall
                      >
                        <span className="number" ref={countUpRef} />
                      </ReactVisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
                Completed <br />
                Projects
              </span>
            </div>

            <div className="counter-column">
              <strong data-number="5">
                <CountUp
                  {...rest}
                  suffix="+"
                  start={viewPortEntered ? null : 0}
                  end={5}
                >
                  {({ countUpRef }) => {
                    return (
                      <ReactVisibilitySensor
                        active={!viewPortEntered}
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setViewPortEntered(true);
                          }
                        }}
                        delayedCall
                      >
                        <span className="number" ref={countUpRef} />
                      </ReactVisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
                Happy <br />
                Clients
              </span>
            </div>

            <div className="counter-column">
              <strong data-number="100K">
                <CountUp
                  {...rest}
                  start={viewPortEntered ? null : 0}
                  end={42}
                  suffix="k+"
                >
                  {({ countUpRef }) => {
                    return (
                      <ReactVisibilitySensor
                        active={!viewPortEntered}
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setViewPortEntered(true);
                          }
                        }}
                        delayedCall
                      >
                        <span className="number" ref={countUpRef} />
                      </ReactVisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
                Working <br />
                Hours{" "}
              </span>
            </div>

            <div className="counter-column">
              <strong data-number="2">
                <CountUp
                  {...rest}
                  start={viewPortEntered ? null : 0}
                  end={2}
                  suffix="+"
                >
                  {({ countUpRef }) => {
                    return (
                      <ReactVisibilitySensor
                        active={!viewPortEntered}
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setViewPortEntered(true);
                          }
                        }}
                        delayedCall
                      >
                        <span className="number" ref={countUpRef} />
                      </ReactVisibilitySensor>
                    );
                  }}
                </CountUp>
              </strong>
              <span>
                Years In <br />
                Business
              </span>
            </div>
          </div>
        </section>
      </div>
      <SectionSections />
      <FooterBlack />
    </>
    //projects sections
  );
}

export default Moreprojects;
