/*eslint-disable*/
import React from 'react'
import { Link } from 'react-router-dom'

// reactstrap components
import { Button, Container, Row } from 'reactstrap'

// core components

function FooterBlack() {
  return (
    <>
      <footer className='footer footer-black footer-white'>
        <Container>
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            <nav className='footer-nav'>
              <ul>
                <li>
                  <a href='tel:+92 347 5214748s'>+92 347 5214748</a>
                </li>
                <li>
                  <a href='mailto:contact@devtronics.co'>
                    contact@devtronics.co
                  </a>
                </li>
              </ul>
            </nav>
            <div className='credits' style={{ margin: 'auto' }}>
              <Button
                href='https://twitter.com/Devtronics1'
                target='_blank'
                className='btn-just-icon mr-1'
                style={{ margin: '0 15px' }}
                color='twitter'
              >
                <i className='fa fa-twitter' />
              </Button>
              <Button
                href='https://www.instagram.com/devtronics1/'
                target='_blank'
                className='btn-just-icon mr-1'
                style={{ margin: '0 15px' }}
                color='instagram'
              >
                <i className='fa fa-instagram'></i>
              </Button>

              <Button
                href='https://www.facebook.com/devtronics12/'
                className='btn-just-icon mr-1'
                target='_blank'
                color='facebook'
                style={{ margin: '0 15px' }}
              >
                <i className='fa fa-facebook' />
              </Button>
              <Button
                href='https://www.linkedin.com/company/devtronics'
                target='_blank'
                className='btn-just-icon mr-1'
                color='linkedin'
                style={{ margin: '0 15px' }}
              >
                <i className='fa fa-linkedin'></i>
              </Button>
              <Button
                href='https://www.guru.com/freelancers/devtronics'
                target='_blank'
                className='btn-just-icon mr-1 '
                color='google'
                style={{ margin: '0 15px' }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 448 512'
                  height='24'
                  style={{ verticalAlign: 'middle' }}
                >
                  <path
                    fill='#ffff'
                    d='M224 96C135.6 96 64 167.6 64 256s71.6 160 160 160c77.4 0 142-55 156.8-128H256c-17.7 0-32-14.3-32-32s14.3-32 32-32H400c25.8 0 49.6 21.4 47.2 50.6C437.8 389.6 341.4 480 224 480C100.3 480 0 379.7 0 256S100.3 32 224 32c57.4 0 109.7 21.6 149.3 57c13.2 11.8 14.3 32 2.5 45.2s-32 14.3-45.2 2.5C302.3 111.4 265 96 224 96z'
                  />
                </svg>
              </Button>
            </div>
            <div style={{ margin: 'auto', marginTop: '20px' }}>
              © 2024 All Right Reserved, Devtronics
            </div>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default FooterBlack
