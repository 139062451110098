import React from 'react';
import recrulacover from '../../assets/img/recrulacover.png';
import DangerNavbar from 'components/Navbars/DangerNavbar';
import FooterGray from 'components/Footers/FooterGray';

const Recrula = () => {
  return (
    <>
      <DangerNavbar />

      <div style={styles.container}>
        <div style={{background: 'cover'}}>
          <img style={{...styles.cover, backgroundSize: 'cover'}} src={recrulacover} alt='cover' />
        </div>
        <div style={styles.profilePicHolder}>
          <img style={styles.profilePic} src={'https://recrula.com/wp-content/uploads/2022/10/cropped-logo-black-icon-180x180.png'} alt='profile' />
        </div>
        <div style={styles.body}>
          <h1 style={styles.heading}>Recrula - Revolutionizing Recruitment with AI</h1>
          <h3 style={styles.subheading}>Challenge:</h3>
          <p>A leading player in the industry, faced a significant challenge in streamlining their recruitment process. The CEO emphasized the urgency of improving their time-consuming tasks of writing compelling job descriptions and manually screening applicants' CVs. They needed an efficient solution to identify top talent while ensuring a positive candidate experience.</p>
          
          <h3 style={styles.subheading}>Our Approach:</h3>
          <p>We developed Recrula, an AI-powered recruitment platform designed to address these challenges comprehensively. Recrula allows recruiters to create job postings with ease, outlining key requirements and desired skills, reducing the initial workload. It utilizes GPT, a cutting-edge large language model, to generate clear and informative job descriptions, eliminating the difficulty and time associated with writing compelling descriptions manually. Job seekers can easily discover relevant job postings and submit their CVs with a few simple clicks, ensuring a user-friendly application process.</p>
          
          <p>Throughout the project's duration, our team maintained a stringent focus on project management and quality assurance. We meticulously planned every phase of development, ensuring that all milestones were achieved according to the predetermined timeline. This involved continuous communication and collaboration within the team, regular progress assessments, and proactive issue resolution.</p>
          
          <p>Our custom Python script meticulously parses submitted CVs to extract relevant skills, experience, and keywords. The AI then compares this data against the AI-generated job descriptions to identify candidates with the most relevant qualifications. This automation ensures a high degree of accuracy and efficiency in the screening process. Recruiters receive a shortlist of the most promising candidates, allowing them to focus their time and energy on interviewing the best fit for the open position. By integrating these advanced features, Recrula ensures that the recruitment process is efficient, accurate, and user-friendly for both recruiters and candidates.</p>
          
          <h3 style={styles.subheading}>TECHNOLOGIES</h3>
          <ul>
            <li>Node.JS</li>
            <li>React.JS</li>
            <li>Express.Js</li>
            <li>MongoDB</li>
          </ul>

          <h3 style={styles.subheading}>Conclusion</h3>
          <p>Recrula represents a significant advancement in the recruitment landscape by leveraging AI technology to streamline and enhance the hiring process. It offers a comprehensive solution that addresses the specific needs of businesses like X-age Company, enabling them to identify and secure top talent more efficiently and effectively. As we continue to refine and adapt Recrula to evolving recruitment trends, we are committed to revolutionizing the way businesses approach hiring.</p>
        </div>
      </div>

      <FooterGray />
    </>
  );
};

const styles = {
  container: {
    marginTop: '70px',
    position: 'relative',
  },
  cover: {
    // backgroundsize: 'cover',
    // background: 'cover',
    width: '100%',
    height: '570px',
  },
  profilePicHolder: {
    position: 'absolute',
    top: 'calc(100% - 1070px)',
    left: '10%',
    width: '180px',
    height: '180px',
    borderRadius: '50%',
    backgroundColor: 'white',
  },
  profilePic: {
    position: 'absolute',
    top: 'calc(100% - 140px)',
    left: '23%',
    width: '100px',
    height: '100px',
  },
  body: {
    backgroundColor: 'lightblue',
    color: 'black',
    padding: '80px 180px', // Adjusted padding for responsiveness
  },
  heading: {
    marginTop: '40px',
    fontWeight: 500,
    fontSize: '40px',
  },
  subheading: {
    fontWeight: 500,
  },
};

export default Recrula;
