import React from 'react'
import FAQ from 'components/faq'

// core components

function SectionFAQ() {
  return (
    <>
    <div style={{marginLeft:'180px', marginTop:'40px'}}>
      <h6>
        FAQS
      </h6>
      <h3>
        How Can We Help?
      </h3>

    </div>
      <div style={style.faqcontainer}>
        <FAQ
          question="Q:  What services do you provide?"
          answer="We offer a range of software development services, including custom software development, 
          web application development, mobile app development, business process re-engineering, MVP’s for startup’s,
          CRM & ERP development, ODOO implementation, software testing, maintenance, and support"
        />
        <FAQ
          question="Q: What technologies and programming languages do you specialise in?"
          answer="We specialise in various technologies and programming languages, such as Java, Python, NET, PHP, JavaScript, 
          React.JS React Native, Angulor, Node.js, and more. We stay up to date with the latest industry trends and continuously 
          expand our skill set"
        />
        <FAQ
          question="Q:  What is your development process like??"
          answer="Our development process follows agile methodologies. We collaborate closely with clients, conduct regular 
          meetings for requirement gathering, provide regular progress updates, and incorporate feedback throughout the development cycle"
        />
        <FAQ
          question="Q:  How do you handle scalability and future growth?"
          answer="We design our software solutions with scalability in mind. Our architecture and infrastructure 
          allow for seamless scalability, ensuring that your software can accommodate future growth and increased user demand"
        />
        <FAQ
          question="Q:  How do you ensure the security and confidentiality of our project?"
          answer="We prioritise the security of our clients' projects. We follow industry-standard best practices for data protection, 
          implement secure coding practices, and maintain strict confidentiality agreements with our team members"
        />
        <FAQ
          question="Q:  What kind of support and maintenance do you provide post development?"
          answer="We offer ongoing support and maintenance services to address any issues or updates that may arise 
          after the development phase. Our team is available for timely bug fixes, performance optimizations, and feature 
          enhancements. Majority of our clients do sign up a retainer with us."
        />
        <FAQ
          question="Q: What is your pricing structure?"
          answer="Our pricing structure is based on various factors such as project complexity, scope, and duration. 
          We provide detailed project proposals with transparent pricing, ensuring that you have a clear understanding of the cost involved."
        />
      </div>
    </>
  )
}

const style = {
  faqcontainer: {
      width: '80%',
      margin: '0 auto',
      padding: '2rem',
    },
};

export default SectionFAQ
