import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionSections() {
  return (
    <>
      <div className="section section-sections section-gray pb-5">
        <Container>
          <Col className="ml-auto mr-auto" md="8">
            <div className="section-description text-center">
              <h2 className="title">Section you will love</h2>
              <h5 className="description">
                Explore this portfolio to embark on a journey through the world
                of <a href="/">Devtronics</a>, where technology meets
                creativity, and discover how these pre-made sections bring our
                vision to life."
              </h5>
              <br />
            </div>
          </Col>
        </Container>
        <Container fluid className="pb-5">
          <div className="section-cols">
            <Container fluid>
              <Row>
                <Col md="3">
                  <img
                    alt="Michaguru"
                    src={require("assets/img/projects sections/Micahguru.58eeb7ce.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/airtap.1754ad98.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/anastomosis.51498110.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/app.cardlysa.com_register (1).png")}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/beasty.f5f746a8.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/codingschool.f145887a.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/lct-auto.72c905a4.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/monsterhub.4560044d.png")}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/portfolio.0e9dce4f.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/snapthathome.ec124273.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/taskpro.6810b17e.png")}
                  />
                </Col>
                <Col md="3">
                  <img
                    alt="Project"
                    src={require("assets/img/projects sections/walletly.57866643.png")}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionSections;
