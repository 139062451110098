import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionResponsive() {
  return (
    <>
      <div className="section section-responsive section-gold">
        <Container fluid>
          <Row className="justify-content-center justify-content-lg-start">
            <Col md="6">
              <div
                className="phone-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="resposive mobile"
                  src={require("assets/img/Responsive mobile.png")}
                />
              </div>
            </Col>
            <Col lg="4">
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-tablet-2" />
                </div>
                <div className="description">
                  <h4 className="info-title">Responsive Web Design</h4>
                  <p>
                    Our designs provide an excellent adjustment with the
                    technology in terms of size, every of it's component is
                    designed in such a way, that gives an excellent
                    representation of the data The kit is designed to be mobile
                    first. Every components looks great on any screen size.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionResponsive;
