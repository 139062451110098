import React from 'react'

// reactstrap components
import { Container, Row, Col } from 'reactstrap'
// core components

function SectionSummary() {
  return (
    <>
      <div className='section section-dark section-summary'>
        <Container>
          <Row>
            <Col md='4'>
              <div className='info'>
                <div className='icon icon-danger'>
                  <i className='nc-icon nc-layout-11' />
                </div>
                <div className='description'>
                  <h4 className='info-title'>Software Solutions</h4>
                  <p>
                    We specialize in creating web applications, CMS, ERP
                    systems, SAAS, Fintech software and provide endless software
                    solutions for SMEs and large organizations around the world.
                  </p>
                </div>
              </div>
            </Col>
            <Col md='4'>
              <div className='info'>
                <div className='icon icon-danger'>
                  <i className='nc-icon nc-tile-56' />
                </div>
                <div className='description'>
                  <h4 className='info-title'>Digital Marketing</h4>
                  <p>
                    Let Devtronics help your business reach its full potential
                    with our creative digital marketing solutions. We'll analyze
                    your industry, establish goals and create an effective
                    strategy to achieve them.
                  </p>
                </div>
              </div>
            </Col>
            <Col md='4'>
              <div className='info'>
                <div className='icon icon-danger'>
                  <i className='nc-icon nc-paper' />
                </div>
                <div className='description'>
                  <h4 className='info-title'>Design</h4>
                  <p>
                    Our Design experts will help you to communicate your message
                    in a unique way to your prospective customers. We can help
                    with creating logos, brochures, posters and web sites.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default SectionSummary
